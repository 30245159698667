<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 mt-3">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Afficher</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entrées</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filterInput"
                  type="search"
                  placeholder="Saisissez votre recherche"
                  @keyup.enter="applyFilter"
                />
                <b-input-group-append>
                  <b-button
                    @click="applyFilter"
                  >
                    Rechercher
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          />
          <!-- Filter On -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              v-slot="{ ariaDescribedby }"
              v-model="sortDirection"
              description="Laissez tout décoché pour filtrer sur toutes les données"
              class="mt-0"
            >
              <b-form-checkbox-group
                v-model="filterOn"
                :aria-describedby="ariaDescribedby"
                class="mt-1 mb-0"
              >
                <b-form-checkbox
                  class="mb-1"
                  value="ref"
                >
                  Référence
                </b-form-checkbox>
                <b-form-checkbox
                  class="mb-1"
                  value="name"
                >
                  Nom
                </b-form-checkbox>
                <b-form-checkbox
                  class="mb-1"
                  value="phone"
                >
                  Téléphone
                </b-form-checkbox>
                <b-form-checkbox
                  class="mb-1"
                  value="email"
                >
                  Email
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Tableau -->
        <b-row>
          <b-col cols="12">
            <b-table
              id="datatables"
              class="mt-4 mb-4"
              :items="Clients"
              :fields="fields"
              stacked="md"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              striped
              small
              @filtered="onFiltered"
              @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
            >
              <!-- Details column -->
              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col
                      sm="3"
                      class="text-sm-left"
                    >
                      <b>Téléphone :</b>
                    </b-col>
                    <b-col>{{ row.item.phone }}</b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col
                      sm="3"
                      class="text-sm-left"
                    >
                      <b>Email :</b>
                    </b-col>
                    <b-col>{{ row.item.email }}</b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col
                      sm="3"
                      class="text-sm-left"
                    >
                      <b>Adresse :</b>
                    </b-col>
                    <b-col>
                      {{ row.item.adresse }} {{ row.item.cp }} {{ row.item.ville }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col
                      sm="3"
                      class="text-sm-left"
                    >
                    </b-col>
                    <b-col class="text-sm-right">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        class="btn-icon rounded-circle"
                        :href="'https://www.google.com/maps/search/?api=1&query='+row.item.adresse+' '+row.item.cp+' '+row.item.ville"
                        target="_blank"
                      >
                        <feather-icon icon="MapPinIcon" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data" >
                <b-button
                  :to="{name: 'viewclient', params: { id: data.item.key }}"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-success"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="PlusCircleIcon" />
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          />
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BTable,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PouchDB from 'pouchdb'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTable,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        // {key: 'show_details', label: '', thStyle: { width: '10px'}},
        { key: 'actions', label: '', thStyle: { width: '10px' } },
        { key: 'ref', label: 'Référence', sortable: true },
        { key: 'name', label: 'Nom', sortable: true },
        { key: 'email', thClass: 'd-none', tdClass: 'd-none' },
        { key: 'phone', thClass: 'd-none', tdClass: 'd-none' },
      ],
      Clients: [],
      isLoading: true,
      drawer: true,
      perPage: 50,
      currentPage: 1,
      totalRows: 1,
      currentPage: 1,
      filterInput: '',
      pageOptions: [10, 25, 50, 100],
      filter: null,
      outlined: true,
      filterOn: ['ref'],
      sortBy: 'ref',
      sortDirection: 'asc',
      sortDesc: false,
    }
  },
  computed: {
    /**
      * Total no. of records
      */
    rows() {
      return this.items.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {},
  created() {
    this.localDB = new PouchDB('clients')
    this.remoteDB = new PouchDB('https://couchdb.placedusite.fr/clients')
    // this.localDB.sync(this.remoteDB, { live: true, retry: true })
    // this.localDB = true
    // this.localDB.allDocs({ include_docs: true, attachments: true, startkey: 'dd49', endkey: 'dd49\ufff0' }, this.setClients);

    this.localDB.sync(this.remoteDB).on('complete', (complete) => {

      // Création d'un index de recherche
      this.localDB.createIndex({
        index: { fields: ['type'] },
      })

      // Lecture des document sur l'index de recherhe type=clients
      this.localDB.find({ selector: { type: 'clients' } }, this.setClients)

    }).on('error', (err) => {
      // boo, we hit an error!
      alert("error")
    });

  },
  mounted() {},
  customButtons: {
    default: true,
  },
  methods: {
    setClients(err, doc) {
      if (err) {
        console.log('err', err)
      }
      console.log('doc', doc)
      for (let i = 0; i < doc.docs.length; i++) {
        this.Clients.push({
          key: doc.docs[i]._id,
          ref: doc.docs[i].ref,
          name: doc.docs[i].name,
          email: doc.docs[i].email,
          phone: doc.docs[i].phone,
          adresse: doc.docs[i].adresse,
          cp: doc.docs[i].cp,
          ville: doc.docs[i].ville,
        })
      }
      this.totalRows = this.Clients.length
    },
    fetchToDos() {
      this.localDB.allDocs({ include_docs: true, descending: true }, this.setToDos)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    applyFilter() {
      this.filter = this.filterInput
    },
  },
}
</script>

<style>
    .btn-primary {
        margin-right: 12px;
    }
    .per-page-selector {
      width: 90px;
    }
    .btNopadding button {
      padding: 0;
    }
    @media (max-width: 767.98px) {
      .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
        text-align: left;
      }
    }
</style>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-select.scss';
</style>
